<template>
  <div>
    <van-loading v-if="alaoding" style="text-align: center;margin-top:50px;" type="spinner" />
    <div v-else>
      <div class="header">
        <van-icon name="arrow-left" class="arrow_left" alt="" @click="goBack" />
        <span>领取优惠券</span>
        <div style="width:21px"></div>
      </div>
      <div class="top">
        <img v-if="getStatu == 1" src="@/static/get_success.png" alt="">
        <img v-else src="@/static/already.png" alt="">
      </div>
      <div :class="getStatu==1?'top_success':'top_success1'">
        <img v-if="getStatu == 1" src="@/static/icon_success.png" alt="">
        <span v-else>{{ statusTitle }}</span>
      </div>
      <div v-if="getStatu == 1" class="suc_msg">恭喜你领取成功! </div>
      <div v-else class="suc_msg">{{ statusTitle }}</div>
      <div v-if="getStatu == 1" class="content">
        <p>优惠券已放入您的<span>{{ short_name }}账户中</span></p>
        <p v-if="expire_days && expire_days.length > 0" class="expire_days">优惠券有效期{{ expire_days }}天,请立即使用</p>
      </div>
      <div class="btn_box">
        <van-button block round color="linear-gradient(to right, #FFEFCA, #FEE6B8)" @click="go_coupon">
          <span style="color:#6C4124">查看我的优惠券</span>
        </van-button>
        <van-button text="立即使用" block round color="linear-gradient(133deg, #F76262 0%, #EE2E2E 100%)" @click="btnClick">
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getagencyInfo } from '@/api/hometop.js'
import { get_ones_yhq } from '@/api/my_user.js'

export default {
  components: {
  },
  data() {
    return {
      title_msg: '领取优惠卷',
      statusTitle: '领取成功',
      getStatu: 0,
      short_name: '',
      lesson_id: '',
      expire_days: '',
      get_agency_id: '',
      coupon_id: this.$route.query.id || '',
      share_uid: this.$route.query.share_uid || '',
      lesson_url: '',
      alaoding: false
    }
  },
  mounted() {
    this.getAgencyInfoFunc()
    this.getInfo()
    if (this.$route.query.status == 1) {
      this.getStatu = 1
      this.statusTitle = '领取成功'
    } else {
      this.getStatu = 2
      this.statusTitle = '领取失败'
    }
  },
  methods: {
    getInfo() {
      this.alaoding = true
      var params = {
        id: this.coupon_id,
        uid: this.share_uid
      }
      get_ones_yhq(params).then(res => {
        if (res.errNo == 0) {
          const result = res.result
          this.lesson_id = result.lesson_id || ''
          this.expire_days = result.expire_days ? result.expire_days + '' : ''
          const is_tk_lesson = result.is_tk_lesson || 0
          if (is_tk_lesson == 1 || window.location.origin.includes('zk')) {
            this.lesson_url = window.origin + '/selectSubject'
          } else {
            this.lesson_url = window.origin + `/edu/d/${this.lesson_id}.html`
          }
        } else {
          this.msg = res.msg
        }
        this.alaoding = false
      }).catch(() => {
        this.alaoding = false
      })
    },
    goBack() {
      this.$router.go(-1)
    },
    getAgencyInfoFunc() {
      getagencyInfo().then(res => {
        if (res.errNo == 0) {
          const result = res.result || {}
          this.short_name = result.name
          this.get_agency_id = result.agency_id
          if (window.location.href.includes('zk')) {
            this.short_name = '注考帮'
          }
        }
      })
    },
    go_coupon() {
      if (this.loading) {
        return
      }
      var host = window.location.origin
      const my_coupon = host + '/ucenter/coupon'
      window.location.href = my_coupon
    },
    btnClick() {
      if (this.loading) {
        return
      }
      window.location.href = this.lesson_url
    }
  }
}
</script>

<style lang="scss" scoped>
.failBoxImg {
  width: 597px;
  height: 375px;
  border: 3px solid #ccc;
  margin: 0 auto;
  margin-top: 107px;
  display: flex;
  align-items: center;
  img {
    width: 521px;
    height: 292px;
    margin: 0 auto;
  }
}
.failBox_p {
  text-align: center;
  font-size: 30px;
  padding: 50px 0 0 0;
}
.successBox {
  margin-top: 106px;
  p:nth-child(1) {
    text-align: center;
    color: #333;
    font-size: 30px;
  }
  p:nth-child(2) {
    text-align: center;
    color: #333;
    font-size: 30px;
    padding: 20px 0 0 0;
  }
}
.statusTitle {
  p:nth-child(1) {
    font-size: 40px;
    font-weight: 600;
    color: #333;
    text-align: center;
    padding-top: 33px;
  }
}
.goZhulong {
  height: 120px;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 3px solid #ccc;
  img {
    width: 71px;
    height: 71px;
    margin-left: 30px;
  }
  .goZhulong_title {
    margin-left: 28px;
    p:nth-child(1) {
      font-size: 30px;
      color: #333;
    }
    p:nth-child(2) {
      color: #494949;
      letter-spacing: 1px;
    }
  }
  .goZhulong_btn {
    margin-left: 106px;
    button {
      width: 186px;
      height: 56px;
      border: 3px solid #ee2e2e;
      background: transparent;
      color: #ee2e2e;
      border-radius: 12px;
    }
  }
}
.statusImg {
  width: 100%;
  margin: 124px 0 0 0;
  img {
    display: block;
    width: 91px;
    height: 91px;
    margin: 0 auto;
  }
}
.all {
  background: url("../../static/images/my_index/givesbg.png") no-repeat;
  background-size: 100% 100%;
  height: 100vh;
  width: 100%;
  // position: relative;
  // bottom: 30px;
  // overflow: hidden;
}
.my_headers {
  border-bottom: 1px solid #e1e1e1;
}
.headerPage{
  // position: fixed;
  // top: 0;
  // left: 0;
  width: 100%;
  z-index: 1000;
  background: #fff;
}
.header{
  width: 100%;
  padding: 0 40px;
  position: absolute;
  top:100px;
  color:#fff;
  font-size: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  .arrow_left{
      font-size: 42px;
    }
}
.top_success{
  margin: -150px auto 0;
  width: 260px;
  height: 260px;
  img{
    display: block;
    width: 100%;
    height: 100%;
  }
}
.top_success1{
  margin: -200px auto 0;
  width: 100%;
  height: 260px;
  font-size: 42px;
  color:#fff;
  text-align: center;
}
.top{
  img{
    // width: 750px;
    // height: 243px;
    width: 100%;
    height: 100%;
  }
}
.suc_msg{
  color: #939393;
  font-size: 28px;
  margin: 35px 0 10px;
  text-align: center;
}
.content{
  color: #939393;
  text-align: center;
  font-size: 26px;
  margin-bottom: 103px;
  p{
    margin-bottom:10px;
  }
  .expire_days{
    color:#ee2e2e;
  }
}
.btn_box{
  padding: 20px;
  display: flex;
  justify-content: space-between;
  .van-button{
    width: 48.5%;
  }
}
</style>
